<div class="modal">
  <div class="header-row">
    <div class="title-container">
      <mat-icon class="icon" fontSet="material-icons-round">sort</mat-icon>
      <span class="ktitle-medium title">Advanced Filters</span>
    </div>
    <mat-icon class="icon close" fontSet="material-icons-round" (click)="closeFiltersModal()">close</mat-icon>
  </div>
  <div class="content">
    <span>Filter By Assignment</span>
    <div class="row">
      <mat-checkbox [(ngModel)]="showManagedUsers"></mat-checkbox>
      <span>Show only users assigned to me</span>
    </div>
  </div>
  <div class="buttons">
    <app-button [icon]="'sort'" [text]="'Save Filter Selections'" (click)="applyFilters()"></app-button>
  </div>
</div>