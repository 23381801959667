import {
  Component, Inject, OnDestroy, OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';import { CourseRequestsService } from 'src/app/services/course-requests.service';

@Component({
  selector: 'app-my-course-request-details',
  templateUrl: './my-course-request-details.component.html',
  styleUrls: [ './my-course-request-details.component.scss' ],
})
export class MyCourseRequestDetailsComponent implements OnInit, OnDestroy {
  course: {
    courseRequestId: '',
    name: '',
    creditHours: '',
    courseType: '',
    instructor: '',
    cost: '',
    webUrl: '',
    description: '',
    async: false,
    virtual: false,
    anytime: false,
    location: '',
    roomNumber: '',
    courseDate: '',
    startTime: '',
    endTime: '',
  }
  isLoading: any;
  courseRequestSubscription: any;

  constructor(
    public dialogRef: MatDialogRef<MyCourseRequestDetailsComponent>,
    public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
    private courseRequestsService: CourseRequestsService,
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.courseRequestSubscription = this.courseRequestsService.getCourseRequest(this.data.courseId).subscribe({
      next: (response: any) => {
        this.course = {
          ...response.data,
          name: response.data.courseName,
          creditHours: response.data.trackingValue,
          courseType: response.data.CourseType.courseType,
          instructor: response.data.CourseItems[0].instructor,
          cost: response.data.courseCost,
          webUrl: response.data.CourseItems[0].hyperlink,
          description: response.data.courseDescription,
          async: response.data.CourseItems[0].preRecordedOption === 'ASYNC',
          virtual: response.data.CourseItems[0].virtualFlag === 1,
          anytime: response.data.CourseItems[0].preRecordedOption === 'ANYTIME',
          location: response.data.CourseItems[0].location,
          roomNumber: response.data.CourseItems[0].roomNumber,
          courseDate: new Date(response?.data?.CourseItems[0]?.dateOffered).toLocaleDateString(),
          startTime: new Date(response?.data?.CourseItems[0]?.sessionStartDate).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' }),
          endTime: new Date(response?.data?.CourseItems[0]?.sessionEndDate).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' }),
        }
        this.isLoading = false;
      },
      error: (error: any) => {
        console.error('Error getting course request', error);
        this.isLoading = false;
      },
    });
  }

  cancelRequest(): void {
    this.dialogRef.close('cancel-request');
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.courseRequestSubscription.unsubscribe();
  }

}
