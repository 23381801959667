import {
  Component, Inject, OnDestroy, OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ToastService } from 'src/app/services/toast.service';
import { ValidationService } from 'src/app/services/validation.service';

@Component({
  selector: 'app-course-validation-details',
  templateUrl: './course-validation-details.component.html',
  styleUrls: [ './course-validation-details.component.scss' ],
})
export class CourseValidationDetailsComponent implements OnInit, OnDestroy {
  tableTitles = [
    { name: 'Full Name' },
    { name: 'Email', class: 'email' },
    { name: 'Hours' },
    { name: 'Status', class: 'status' },
  ]
  isLoading = true;
  sessionValidationDetails: any;
  selectedSession: any
  updatedValidations: {
    user: any;
    statusKid: string;
    adjustedValue: number;
  }[] = [];
  originalValidations: {
    user: any;
    originalStatusKid: string;
    originalAdjustedValue: number;
  }[] = [];
  bulkSubscription: any;
  validationSubscription: Subscription;

  constructor(
    private validationService: ValidationService,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CourseValidationDetailsComponent>,
  ) { }



  ngOnInit(): void {
    this.selectedSession = this.data
    this.validationService.clearSessionValidationDetails();
    this.validationService.getSessionValidationDetails(this.data);

    this.validationSubscription = this.validationService.validationSessionData$
      .subscribe((response: any) => {
        if (!response) return;

        this.sessionValidationDetails = response;
        this?.sessionValidationDetails?.rows.forEach((user: any) => {
          const cloneUser = JSON.parse(JSON.stringify(user));

          this.originalValidations.push({
            user: cloneUser,
            originalStatusKid: cloneUser.currentStatusKid,
            originalAdjustedValue: cloneUser.adjustedValue,
          })

          this?.updateIndividualValidation(user, user.currentStatusKid);
        })
      })

  }

  closeDialog = () => {
    this.dialogRef.close();
  }

  updateIndividualValidation = (user: any, validation: any) => {
    // if the user is already in the updatedValidations array, remove it
    const userIndex = this.updatedValidations.findIndex((updatedValidation: any) => updatedValidation.user.loginName === user.loginName);
    if (userIndex !== -1) {
      this.updatedValidations.splice(userIndex, 1);
    }
    // if the user is unmarked, don't add them to the updatedValidations array
    if (validation === null) {
      return;
    }
    // add the updated user to the updatedValidations array
    const updatedValidation = {
      user: user,
      statusKid: validation,
      adjustedValue: Number(user.adjustedValue),
    }
    // add the updated user to the updatedValidations array
    this.updatedValidations.push(updatedValidation);
  }

  approveAll = () => {
    this.sessionValidationDetails.rows.forEach((user: any) => {
      this.updateIndividualValidation(user, 'COMPLETED');
    })
  }

  unmarkAll = () => {
    this.sessionValidationDetails.rows.forEach((user: any) => {
      this.updateIndividualValidation(user, 'REGISTERED');
    })
  }

  denyAll = () => {
    this.sessionValidationDetails.rows.forEach((user: any) => {
      this.updateIndividualValidation(user, 'NOT_TAKEN');
    })
  }


  // these are functions for styling the buttons
  isApproved = (user: any) => {
    const isApprovedArray = this?.updatedValidations?.filter((validatedUser: any) => validatedUser.user.loginName === user.loginName && validatedUser.statusKid === 'COMPLETED');
    return isApprovedArray.length > 0;
  }

  isUnmarked = (user: any) => {
    const isUnmarkedArray = this?.updatedValidations?.filter((validatedUser: any) => validatedUser.user.loginName === user.loginName);
    return !(isUnmarkedArray.length > 0);
  }

  isDenied = (user: any) => {
    const isDeniedArray = this?.updatedValidations?.filter((validatedUser: any) => validatedUser.user.loginName === user.loginName && validatedUser.statusKid === 'NOT_TAKEN');
    return isDeniedArray.length > 0;
  }

  // this styles the select all buttons
  styleButton = (status: any) => {
    if (status === 'COMPLETED') {
      const markedCompleted = this.updatedValidations.filter((validatedUser: any) => validatedUser.validation === 'COMPLETED');
      const isSelected = markedCompleted.length > 0 && markedCompleted.length === this.sessionValidationDetails?.rows?.length;
      return isSelected ? true : false;
    } else if (status === 'NOT_TAKEN') {
      const markedDenied = this.updatedValidations.filter((validatedUser: any) => validatedUser.validation === 'NOT_TAKEN');
      const isSelected = markedDenied.length > 0 && markedDenied.length === this.sessionValidationDetails?.rows?.length;
      return isSelected ? true : false;
    }
    return
  }

  updateAdjustedValue(user: any, event: Event) {
    const eventValue = (event.target as HTMLInputElement).value;
    const updatedValidation = this.updatedValidations.find((updatedValidation: any) => {
      return updatedValidation.user.loginName === user.loginName;
    });
    if (updatedValidation) {
      updatedValidation.adjustedValue = Number(eventValue);
    }
  }

  getUpdatedUsers() {
    const originalValidations = this.originalValidations;
    const updatedValidations = this.updatedValidations;


    return updatedValidations.filter((updatedValidation) => {
      const originalValidation = originalValidations.find((originalValidation) => originalValidation.user.loginName === updatedValidation.user.loginName);

      if (!originalValidation) {
        throw new Error('Original validation not found');
      }

      const validationSame = updatedValidation.statusKid === originalValidation.originalStatusKid;
      const adjustedValueSame = updatedValidation.adjustedValue == originalValidation.originalAdjustedValue;

      return !(validationSame && adjustedValueSame);
    });
  }

  // this saves the updated validations
  saveValidationUpdates = () => {
    const updatedUsers = this.getUpdatedUsers();

    const formattedUpdatedValidations = updatedUsers.map((updatedValidation) => {
      return {
        id: parseFloat(updatedValidation.user.userCourseId),
        currentStatusKid: updatedValidation.statusKid,
        adjustedValue: updatedValidation.adjustedValue,
      }
    })
    // here we remove any validations that haven't been updated
    const filteredValidations = formattedUpdatedValidations.filter((validation: any) => validation.currentStatusKid !== validation.originalStatusKid);
    // here we are packaging the data to send to the backend
    const validationPackage = {
      courseId: this.selectedSession.courseId,
      courseItemId: this.selectedSession.courseItemId,
      updateArr: formattedUpdatedValidations,
    }
    this.isLoading = true;
    this.bulkSubscription = this.validationService.bulkValidate(validationPackage).subscribe({
      next: (response: any) => {
        this.dialogRef.close('result');
        this.isLoading = false;
        this.toastService.setToast({
          text: 'Validations updated successfully', type: 'success', icon: true,
        })
      },
      error: (error: Error) => {
        this.isLoading = false;
        console.log('error', error);
        throw error;
      },
    })

  }

  ngOnDestroy(): void {
    this.validationSubscription.unsubscribe();
    this.bulkSubscription?.unsubscribe();
  }
}
