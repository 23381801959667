import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import Course from '../../course.class';

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: [ './general-info.component.scss' ],
})
export class GeneralInfoComponent {
  @Output() stepForward = new EventEmitter<boolean>();
  @Output() stepBack = new EventEmitter<any>();
  @Output() closeDialogEmitter = new EventEmitter<any>();
  @Input() courseTypes: any;
  @Input() course: Course;
  @Input() isEditMode: any;
  @Input() isCourseRequestApproval: any;

  constructor(public dialog: MatDialog) {
  }

  updateCourseParameter = (
    event: any, parameter: keyof Course, type?: string,
  ) => {
    if (parameter === 'courseType') {
      const selectedType = this.courseTypes.find((courseType: any) => courseType.courseType === event?.target?.value);
      this.course.setProperty('courseType', selectedType);
      this.course.setProperty('courseTypeId', selectedType?.id);
      return
    }
    if (parameter === 'courseSize') {
      return this.course.setProperty(parameter, Number(event?.target?.value));
    }

    this.course.setProperty(parameter, event?.target?.value);
  }

  makePositive = () => {
    // this.course.creditHours = Math.abs(this.course.creditHours)
  }

  updateCourse = () => {
    if (this.course.courseName === '' ||  this.course.trackingValue === '' ||  this.course.courseType === '' ||  !this.course.courseSize ||  this.course.courseDescription === '') {
      return
    }
    this.stepForward.emit(true);
  }

  closeDialog = () => {
    this.closeDialogEmitter.emit();
  }

}
