<div class="course-requests">
  <span class="ktitle-medium title">Course Requests</span>
  <div class="sub-nav">
    <div class="sub-tabs">
      <div
        *ngFor="let section of defaultSections"
        (click)="toggleSelectedSection(section)"
        class="sub-tab"
        [ngClass]="{ selected: selectedSection === section }"
      >
        <mat-icon class="icon" fontSet="material-icons-round">{{
          section.icon
        }}</mat-icon>
        <span class="klabel-large">{{ section.text }}</span>
      </div>
    </div>
    <div class="content special-course-list">
      <app-table
        [tableData]="tableData"
        [hideShowDetailsButton]="false"
        [hideViewInactiveButton]="true"
        [hideAdvancedFilters]="!manageAllUsers"
        [hideEditButton]="true"
        [hideCreateNewButton]="true"
        [showApprovalButton]="
          selectedSection.id === 0 || selectedSection.id === 2
        "
        [showDenialButton]="selectedSection.id === 0"
        [isLoading]="isLoading"
        (approveItem)="(true)"
        (sortColumn)="sortColumn($event)"
        (itemsPerPageChange)="onItemsPerPageChange($event)"
        (pageChanged)="onPageChanged($event)"
        (showItemDetails)="showItemDetails($event)"
        (approveItem)="openCourseRequestResponse($event, 'approve')"
        (denyItem)="openCourseRequestResponse($event, 'deny')"
        (toggleAdvancedFilters)="toggleAdvancedFilters()"
        (filterColumn)="searchCourseRequests($event)"
      ></app-table>
      <app-loading-spinner
        class="spinner"
        *ngIf="isLoading"
      ></app-loading-spinner>
    </div>
  </div>
</div>
