import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-course-request-general-info',
  templateUrl: './course-request-general-info.component.html',
  styleUrls: [ './course-request-general-info.component.scss' ],
})
export class CourseRequestGeneralInfoComponent {
  @Input() course: any;
  @Input() courseTypes: any;
  // formattedCourseDate: any = new Date();


  constructor() { }

  updateCourse = (event: any, parameter: string) => {
    this.course[parameter] = event.target.value;
    if (parameter === 'courseType') {
      // find the course type from the course types array
      const selectedCourseType = this.courseTypes.find((courseType: any) => courseType.courseType === event.target.value);
      this.course.courseTypeObject = selectedCourseType;

    }
  }

  asyncToggle = () => {
    this.course.async ? this.course.anytime = false : null;
    this.course.async ? this.course.courseStartTime = '' : null;
    this.course.async ? this.course.courseEndTime = '' : null;
    this.course.async ? this.course.courseDate = '' : null;
  }

}
