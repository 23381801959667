<div
  class="master-row"
  *ngIf="
    (!hideTitle || !hideCreateNewButton || !hideEditButton || !hideFilter) &&
    tableData
  "
>
  <div class="titles" *ngIf="!hideTitle">
    <span *ngIf="showActiveItems" class="kheader-strong">{{
      tableData.tableTitle
    }}</span>
    <span *ngIf="!showActiveItems" class="kheader-strong"
      >Inactive {{ tableData.tableTitle }}</span
    >
  </div>

  <div
    class="filters"
    *ngIf="!hideCreateNewButton || !hideEditButton || !hideFilter"
  >
    <app-button
      *ngIf="!hideCreateNewButton"
      (click)="addNew()"
      class="button"
      [text]="
        '+ Add New ' +
        (tableData.buttonLabel
          ? tableData.buttonLabel
          : tableData.noTitleSlice
          ? tableData.tableTitle
          : tableData.tableTitle?.slice(0, -1))
      "
    ></app-button>

    <app-button
      (click)="toggleActive()"
      [icon]="{ materialIcon: 'visibility' }"
      *ngIf="!hideViewInactiveButton"
      text="{{ !showActiveItems ? 'Hide Inactive' : 'View Inactive' }}"
      [class]="'grey'"
    ></app-button>

    <div *ngIf="tableData?.allSchoolYears">
      <select
        [(ngModel)]="selected || tableData.allSchoolYears[0]"
        class="kbody-medium school-year"
      >
        <option
          *ngFor="let year of tableData.allSchoolYears"
          value="{{ year }}"
        >
          {{ year }}
        </option>
      </select>
    </div>
    <app-input
      *ngIf="!hideSearchBar"
      class="search"
      (updatedValue)="searchColumn($event)"
      [value]="searchText"
      [debounceTime]="300"
      placeholder="Search"
    ></app-input>
    <app-button
      *ngIf="!hideAdvancedFilters"
      [icon]="{ materialIcon: 'filter_list' }"
      [text]="'Advanced Filters'"
      class="grey"
      (click)="openAdvancedFiltersModal()"
  ></app-button>
  </div>
</div>

<div class="table" *ngIf="tableData">
  <div class="title-row">
    <div class="titles" [ngClass]="{ 'space-between' : tableData?.noLineButtons }">
      <div 
        (click)="sortColumnEvent(titleObject.title, titleObject.sortBy)" 
        class="title-container"
        [ngClass]="
          (titleObject.class ? titleObject.class : '') +
          (tableData?.noLineButtons ? ' space-between' : '')
        "
        *ngFor="let titleObject of tableData?.columnTitles"
      >
        <mat-icon *ngIf="titleObject.icon" fontSet="material-icons-round">{{
          titleObject.icon
        }}</mat-icon>
        <!-- <mat-icon *ngIf="!titleObject.noCircle" class="circle">fiber_manual_record</mat-icon> -->
        <span class="title klabel-large">{{ titleObject.title }}</span>
        <mat-icon
          class="arrow"
          [ngClass]="{
            ascending:
              titleObject.title === sortOrder.title &&
              sortOrder.direction === 'ASC'
          }"
          *ngIf="!titleObject.notClickable"
          >arrow_upward</mat-icon
        >
      </div>
      <div style="width: 40px"></div>
    </div>
  </div>
  <div class="items tall" [ngClass]="{ tall: tableData.isStaffList }">
    <span
      class="error"
      *ngIf="!isLoading && (tableData?.error || tableData?.data?.length === 0)"
      >Sorry, no items found.</span
    >
    <div
      class="item kbody-medium"
      *ngFor="let item of tableData?.data"
      (click)="handleRowClick(item)"
    >
      <div
        class="details"
        [ngClass]="{ 'space-between': tableData.noLineButtons }"
      >
        <span
          class="detail"
          *ngFor="let detail of item.itemData"
          [ngClass]="detail.class"
          [id]="detail.type"
        >
          <div
            *ngIf="detail.type === 'maincolumn'"
            [innerHtml]="detail.maincontent"
          ></div>
          <label *ngIf="detail.type === 'checkbox'" class="container">
            <input type="checkbox" disabled [checked]="detail.checked" />
            <span class="checkmark"></span>
          </label>
          <label *ngIf="detail.type === 'grouping'" class="chipcontainer">
            <ng-template
              [ngIf]="detail.single === 'GROUP_PARENT'"
              [ngIfElse]="single"
            >
              <app-chip
                class="group klabel-medium label"
                [text]="'GROUP'"
              ></app-chip>
            </ng-template>
            <ng-template #single>
              <app-chip
                class="single klabel-medium label"
                [text]="'SINGLE'"
              ></app-chip>
            </ng-template>
          </label>
          <label *ngIf="detail.type === 'status'" class="chipcontainer">
            <ng-template [ngIf]="detail.active === 1" [ngIfElse]="inactive">
              <app-chip
                class="active klabel-medium label"
                [text]="'ACTIVE'"
              ></app-chip>
            </ng-template>
            <ng-template #inactive>
              <app-chip
                class="inactive klabel-medium label"
                [text]="'INACTIVE'"
              ></app-chip>
            </ng-template>
          </label>
          <svg
            class="dot"
            *ngIf="detail.type === 'color'"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            [style]="{ fill: detail?.color }"
          >
            <circle cx="12px" cy="12px" r="12px" />
          </svg>
          <app-avatar *ngIf="detail.avatar" size="md" [inputInitials]="detail.avatar.initials" />
          <div class="content-column">
            {{ detail.content }}
            <div *ngIf="detail.secondLine" class="second-line" [innerHtml]="detail.secondLine"></div>
          </div>
        </span>
        <div
          *ngIf="tableData?.tableTitle !== 'Staff'"
          class="actions"
          id="meatball-container1"
        >
          <mat-icon
            class="icon"
            fontSet="material-icons-round"
            (click)="setMeatball(item, $event)"
            id="meatball1"
            [ngClass]="{ open: meatballMenu === item }"
            >more_horiz</mat-icon
          >
          <div class="pop-up" [ngClass]="{ open: meatballMenu === item }">
            <div
              class="btn kbody-large"
              (click)="showDetails(item, $event)"
              *ngIf="!hideShowDetailsButton"
            >
              <span>View {{ tableData?.tableTitle }} Details</span>
            </div>
            <div
              (click)="toggleEditModal(item, $event)"
              class="btn kbody-large"
              *ngIf="!hideEditButton && checkIfShowEdit(item)"
            >
              <span>Edit {{ tableData.tableTitle }}</span>
            </div>
            <div
              (click)="toggleRemoveItem(item, $event)"
              class="btn kbody-large"
              *ngIf="showRemoveItemButton"
            >
              <span>Remove {{ tableData?.tableTitle }}</span>
            </div>
            <div
              (click)="toggleApproveItem(item, $event)"
              class="btn kbody-large"
              *ngIf="showApprovalButton"
            >
              <span>Approve {{ tableData?.tableTitle }}</span>
            </div>
            <div
              (click)="toggleDenyItem(item, $event)"
              class="btn kbody-large"
              *ngIf="showDenialButton"
            >
              <span>Deny {{ tableData?.tableTitle }}</span>
            </div>
          </div>
        </div>
        <div
          *ngIf="tableData?.tableTitle === 'Staff'"
          class="actions"
          id="meatball-container2"
        >
          <mat-icon
            id="meatball2"
            class="icon"
            fontSet="material-icons-round"
            (click)="setMeatball(item, $event)"
            [ngClass]="{ open: meatballMenu === item }"
            >more_horiz</mat-icon
          >
          <div class="pop-up" [ngClass]="{ open: meatballMenu === item }">
            <div
              class="btn kbody-large"
              routerLink="/profile/{{ item.itemId }}"
            >
              <span>View {{ tableData.tableTitle }} Details</span>
            </div>
            <div
              (click)="toggleStaffEditModal(item, $event)"
              class="btn kbody-large"
              *ngIf="!hideEditButton"
            >
              <span>Edit {{ tableData.tableTitle }}</span>
            </div>
          </div>
        </div>

        <span *ngIf="!tableData" class="kbody-medium no-items"
          >Sorry, no items found</span
        >
      </div>
    </div>

    <span *ngIf="!tableData" class="kbody-medium no-items"
      >Sorry, no items found</span
    >
  </div>

  <app-pagination class="pagination"
  [useOldTableStyling]="true"
  [tableData]="tableData"
  [selectedItemsPerPage]="selectedItemsPerPage"
  [showActiveItems]="showActiveItems"
  (pageChanged)="pageChanged.emit($event)"
  (itemsPerPageChange)="itemsPerPageChange.emit($event)"
  ></app-pagination>
  
</div>
