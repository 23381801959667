<div *ngIf="approvalType === 'deny'" class="modal">
  <div class="title-row">
    <div class="row">
      <mat-icon fontSet="material-icons-round" class="close-icon">do_disturb_alt</mat-icon>
      <span class="kbody-large">Deny Request</span>
    </div>
    <mat-icon fontSet="material-icons-round" class="close-icon" (click)="closeModal()">close</mat-icon>
  </div>
  <div class="content">
    <span class="kbody-large">Please let {{ course.createdAppUser}} know why this course request is being denied.</span>
    <span class="klabel-medium label textarea-label">Enter Message*</span>
    <textarea [(ngModel)]="denialReason" class="kinput" placeholder="Enter a reason for course request denial" rows="5"></textarea>
  </div>
  <div class="buttons">
    <app-button [class]="'white'" text="Cancel" (click)="closeModal()" ></app-button>
    <app-button [disabled]="denialReason === ''" class="red" [icon]="{ materialIcon: 'close' }" (click)="denyRequest()" text="Deny Request" ></app-button>
  </div>
</div>