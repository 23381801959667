import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import Course from '../../course.class';

@Component({
  selector: 'app-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: [ './course-details.component.scss' ],
})
export class CourseDetailsComponent {
  @Output() stepBack = new EventEmitter<any>();
  @Output() stepForward = new EventEmitter<boolean>();
  @Output() closeDialogEmitter = new EventEmitter<any>();
  @Input() course: Course;
  @Input() isEditMode: any;
  @Input() isCourseRequestApproval: any;
  formattedCourseDate: any;

  constructor(public dialog: MatDialog) {
  }

  updateCourseParameter = (event: any, parameter: keyof Course) => {
    const value = event.target.value;

    if (parameter === 'dateOffered') {
      this.course.setProperty(parameter, value);
      this.course.dateOfferedFormatted = value;
      return;
    }

    if (parameter === 'sessionStartDate') {
      this.course.sessionStartDateFormatted = value;
    } else if (parameter === 'sessionEndDate') {
      this.course.sessionEndDateFormatted = value;
    }

    this.course.setProperty(parameter, value);
  }

  updateCourse = () => {
    const isDisabled = this.checkDisabled();

    if (isDisabled) return;

    this.stepForward.emit(true)
  }

  asyncToggle = ($event: Event) => {
    const value = ($event.target as HTMLInputElement).checked;
    this.course.isAsync = value;
    this.course.setProperty('preRecordedOption', value ? 'ASYNC' : '');


    // this.course.isAsync
    this.course.isAnytime = false;
    // this.course.isAsync ? this.course.sessionStartDate = undefined : null;
    // this.course.isAsync ? this.course.sessionEndDate = undefined : null;
    // this.course.isAsync ? this.course.dateOffered = undefined : null;

  }

  updateAnytime($event: Event) {
    const value = ($event.target as HTMLInputElement).checked;
    this.course.isAnytime = value;
    this.course.isAsync = false;
    this.course.setProperty('preRecordedOption', value ? 'ANYTIME' : '');
    // this.course.isAnytime ? this.course.sessionStartDate = undefined : null;
    // this.course.isAnytime ? this.course.sessionEndDate = undefined : null;
  }

  checkDisabled = () => {
    const course = this.course;
    const location = course.location;
    let isDisabled = !location;

    if (isDisabled) return isDisabled;

    const dateOffered = course.dateOffered;
    if (course.isAnytime) {
      isDisabled = !dateOffered;
    } else if (!course.isAsync) {
      const sessionStartDate = course.sessionStartDate;
      const sessionEndDate = course.sessionEndDate;
      isDisabled = !dateOffered && !sessionStartDate && !sessionEndDate;
    }

    if (isDisabled) return isDisabled;

    const regStartDate = course.regStartDate;
    const regEndDate = course.regEndDate;

    isDisabled = !regStartDate && !regEndDate;

    return isDisabled;
  }

  stepBackward = () => {
    this.stepBack.emit();
  }

  closeDialog = () => {
    this.closeDialogEmitter.emit();
  }

}
