import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { VerificationComponent } from 'src/app/components/verification/verification.component';

@Component({
  selector: 'app-course-request-review',
  templateUrl: './course-request-review.component.html',
  styleUrls: [ './course-request-review.component.scss' ],
})
export class CourseRequestReviewComponent {
  @Input() course: any;
  @Input() showStatusRow: any = false;

  constructor( public dialog: MatDialog) {}

  formatDate(date: any): string {
    return new Date(date).toLocaleDateString();
  }

  viewReason() {
    this.dialog.open(VerificationComponent, {
      data: {
        title: 'Reason for Denial',
        text: this.course?.statusComments,
        icon: 'info',
        confirmButtonTitle: 'Ok',
      },
    });

  }


}
