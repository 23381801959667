import {
  Component, Inject, OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';

@Component({
  selector: 'app-advanced-filters',
  templateUrl: './advanced-filters.component.html',
  styleUrls: [ './advanced-filters.component.scss' ],
})
export class AdvancedFiltersComponent implements OnInit {
  showManagedUsers: any;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AdvancedFiltersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.showManagedUsers = this.data.showManagedUsers;
  }

  closeFiltersModal = () => {
    this.dialog.closeAll();
  }

  applyFilters = () => {
    this.dialogRef.close({ showManagedUsers: this.showManagedUsers });
  }

}
