<div class="modal-content">
  <div class="title-row">
    <span class="kheader-strong" >General Information</span>
    <span class="kbody-large note">* indicates a required field</span>
  </div>
  <div class="content">
    <div class="subtitle-container">
      <span class="kcaption-large subtitle">COURSE DETAILS</span>
      <div class="line"></div>
    </div>

    <div class="row">
      <app-input
        [label]="'Course Name*'"
        value="{{this?.course?.name}}"
        [placeholder]="'Enter Course Name'"
        (updatedValue)="updateCourse($event, 'name')"
        [required]="true"
      ></app-input>
      <div class="number-container">
        <span class="klabel-medium label">Hours*</span>
        <input 
          type="number" 
          class="kinput" 
          placeholder="0.00" 
          min="0" 
          step="0.25" 
          (change)="updateCourse($event, 'creditHours')" 
          [required]="true"
          [ngModel]="this?.course?.creditHours"
          #creditHours="ngModel"
          >
        <span class="klabel-medium error" *ngIf="creditHours.invalid && (creditHours.dirty || creditHours.touched)">Credit hours are required</span>
      </div>
      <div class="detail">
        <span class="klabel-medium label">Course Type*</span>
        <div class="input-select-container">
          <input 
            (change)="updateCourse($event, 'courseType')" 
            class="kinput kbody-medium input-select" 
            type="text"
            placeholder="Type or select a course type" 
            list="targets" 
            [ngModel]="course?.courseType.courseType"
            required
            #courseType="ngModel"
            > 
          <datalist id="targets" class="datalist">
            <option class="kbody-medium" *ngFor="let courseType of courseTypes">{{ courseType.courseType }}</option>
          </datalist>
          <span class="klabel-medium error" *ngIf="courseType.invalid && (courseType.dirty || courseType.touched)">Course type is required</span>
        </div>
      </div>
    </div>
    <div class="row">
      <app-input
        [label]="'Instructor'"
        [value]="this?.course?.instructor"
        [placeholder]="'Enter Instructor Name'"
        (updatedValue)="updateCourse($event, 'instructor')"
      ></app-input>
      <div class="number-container">
        <span class="klabel-medium label">Course Cost</span>
        <input 
          type="number" 
          class="kinput" 
          placeholder="0.00" 
          min="0.01" 
          step="0.01" 
          [value]="this?.course?.cost || null"
          (change)="updateCourse($event, 'cost')">
      </div>
      <app-input
        [label]="'Web URL'"
        [value]="this?.course?.webUrl"
        [placeholder]="'Enter URL for course information'"
        (updatedValue)="updateCourse($event, 'webUrl')"
      ></app-input>
    </div>
    <div class="description-container">
      <span class="klabel-medium label">Course Description*</span>
      <textarea 
        placeholder="This is where you can write a description of your course." 
        class="kinput" 
        rows="4" 
        (keyup)="updateCourse($event, 'description')"
        [ngModel]="this?.course?.description"
        required
        #description="ngModel"
        ></textarea>
      <span class="klabel-medium error" *ngIf="description.touched && description.errors">Description is required</span>
    </div>

    <div class="subtitle-container">
      <span class="kcaption-large subtitle">COURSE OFFERING, LOCATION, DATE & TIME</span>
      <div class="line"></div>
    </div>

    <div class="row left">
      <div class="switch-detail">
        <label class="switch">
          <input type="checkbox" [(ngModel)]="course.async" (change)="asyncToggle()">
          <span class="slider round"></span>
        </label>
        <span class="kbody-medium">Async</span>
      </div>
      <div class="switch-detail">
        <label class="switch">
          <input type="checkbox" [(ngModel)]="course.virtual">
          <span class="slider round"></span>
        </label>
        <span class="kbody-medium">Virtual</span>
      </div>
      <div class="switch-detail" *ngIf="!course.async">
        <label class="switch">
          <input type="checkbox" [(ngModel)]="course.anytime">
          <span class="slider round"></span>
        </label>
        <span class="kbody-medium">Anytime</span>
      </div>
    </div>

    <div class="row" *ngIf="course.virtual">
      <app-input
        [label]="'Meeting ID*'"
        [value]="this?.course?.location"
        [placeholder]="'Enter a virtual meeting room URL'"
        (updatedValue)="updateCourse($event, 'location')"
        [required]="true"
        ></app-input>
      </div>
      <div class="row" *ngIf="!course.virtual">
        <app-input
        [label]="'Location*'"
        [value]="this?.course?.location"
        [placeholder]="'Enter location'"
        (updatedValue)="updateCourse($event, 'location')"
        [required]="true"
      ></app-input>
      <app-input
        [label]="'Room Number'"
        [value]="this?.course?.roomNumber"
        [placeholder]="'Enter room number'"
        (updatedValue)="updateCourse($event, 'roomNumber')"
      ></app-input>
    </div>

    <div class="row left" *ngIf="!course.async">
      <div class="detail">
        <span class="klabel-medium label">Course date*</span>
        <input 
          type="date" 
          class="kinput" 
          required
          [(ngModel)]="course.courseDate"
          #coursedate="ngModel"
          >
        <span class="klabel-medium error" *ngIf="coursedate.touched && coursedate.errors">Course Date is required</span>
      </div>
      <div class="detail" *ngIf="!course.anytime">
        <span class="klabel-medium label">Course start time*</span>
        <input 
          type="time"
          required 
          class="kinput" 
          [(ngModel)]="course.startTime"
          #coursestarttime="ngModel"
          >
        <span class="klabel-medium error" *ngIf="coursestarttime.touched && coursestarttime.errors">Course Start Time is required</span>
      </div>
      <div class="detail" *ngIf="!course.anytime">
        <span class="klabel-medium label">Course end time*</span>
        <input 
          type="time" 
          class="kinput"
          required 
          [(ngModel)]="course.endTime"
          #courseendtime="ngModel"
          >
        <span class="klabel-medium error" *ngIf="courseendtime.touched && courseendtime.errors">Course End Time is required</span>
      </div>
    </div>

  </div>
</div>
