<div class="modal">
  <div class="modal-header">
    <div>
      <mat-icon
      fontSet="material-icons-round"
      class="close-icon"
      >post_add</mat-icon>
      <span class="kbody-large">Course Request Details</span>
    </div>
    <mat-icon
      fontSet="material-icons-round"
      class="close-icon"
      (click)="closeModal()"
      >close</mat-icon
    >
  </div>
  <app-loading-spinner class="spinner" *ngIf="isLoading"></app-loading-spinner>
  <app-course-request-review [showStatusRow]="true" *ngIf="!isLoading" [course]="course"></app-course-request-review>
  <div class="button-row" [ngClass]="{'flex-end': course.currentCatalogStatusKid !== 'SPECIAL_REQUEST_PENDING'}">
    <app-button *ngIf="course.currentCatalogStatusKid === 'SPECIAL_REQUEST_PENDING'" [text]="'Deny Request'" [class]="'red'" [icon]="{materialIcon: 'do_disturb_alt'}" (click)="closeModal('deny')"></app-button>
    <div class="actions">
      <app-button [text]="'Cancel'" [class]="'white'" (click)="closeModal()"></app-button>
      <app-button *ngIf="course.currentCatalogStatusKid === 'SPECIAL_REQUEST_PENDING'" [text]="'Approve Request'" [icon]="{materialIcon: 'check'}" (click)="closeModal('approve')" ></app-button>
      <app-button *ngIf="course.currentCatalogStatusKid === 'SPECIAL_REQUEST_APPROVED'" [class]="'grey'" [text]="'Submit as Catalog Suggestion'"></app-button>
      <!-- TODO: Add this Redo Approval button back in when backend functionality is ready -->
      <!-- <app-button *ngIf="course.currentCatalogStatusKid === 'SPECIAL_REQUEST_DENIED'" [class]="'grey'" [text]="'Redo Approval Process'"></app-button> -->
    </div>
  </div>
</div>
