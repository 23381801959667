import {
  Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges, HostListener,
} from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: [ './table.component.scss' ],
})
export class TableComponent{
  @Output() addNewItem = new EventEmitter();
  @Output() sortColumn = new EventEmitter<object>();
  @Output() filterColumn = new EventEmitter<object>();
  @Output() toggleActiveList = new EventEmitter<boolean>();
  @Output() showItemDetails = new EventEmitter<object>();
  @Output() showEditModal = new EventEmitter<object>();
  @Output() showStaffEditModal = new EventEmitter<object>();
  @Output() paginationEvent = new EventEmitter<any>();
  @Output() removeItem = new EventEmitter<object>();
  @Output() approveItem = new EventEmitter<object>();
  @Output() denyItem = new EventEmitter<object>();
  @Output() itemsPerPageChange = new EventEmitter<number>();
  @Output() pageChanged = new EventEmitter<number>();
  @Output() rowClicked = new EventEmitter<any>();
  @Output() toggleAdvancedFilters = new EventEmitter<any>();
  @Input() isLoading = false;
  @Input() tableData: any;
  @Input() hideCreateNewButton: any = false;
  @Input() hideEditButton: any = false;
  @Input() hideViewInactiveButton: any = false;
  @Input() hideSearchBar: any = false;
  @Input() hideAdvancedFilters: any = true;
  @Input() hideShowDetailsButton: any = false;
  @Input() showRemoveItemButton: any = false;
  @Input() hideFilter = false;
  @Input() hideTitle = false;
  @Input() hideIcon = false;
  @Input() searchText = '';
  @Input() showApprovalButton = false;
  @Input() showDenialButton = false;

  selectedItemsPerPage = 25;
  isDropdownOpen = false;

  meatballMenu: any = '';
  prevLink = true;
  nextLink = true;
  links: any;
  selected: string;
  showActiveItems = true;
  sortOrder = {
    title: '', direction: 'DESC', showActiveItems: true,
  };

  constructor() { }

  // on clicking anywhere on the page, set meatballMenu to ''
  @HostListener('document:click', [ '$event' ])
  clickout(event: any) {
    if (event.target.id !== 'meatball-container1' && event.target.id !== 'meatball1' && event.target.id !== 'meatball-container2' && event.target.id !== 'meatball2') {
      this.meatballMenu = '';
    }
  }

  openAdvancedFiltersModal() {
    this.toggleAdvancedFilters.emit();
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  addNew = () => {
    this.addNewItem.emit();
  }

  sortColumnEvent = (title: any, sortBy?: any) => {
    this.sortColumn.emit({
      title: title, sortBy, direction: this.sortOrder.direction, showActiveItems: this.showActiveItems,
    });
    this.sortOrder = {
      title,
      direction: this.sortOrder.direction === 'ASC' ? 'DESC' : 'ASC',
      showActiveItems: this.showActiveItems,
    };
  }

  searchColumn = (event: any) => {
    this.searchText = event.target.value;
    const searchItem = { 'searchText': this.searchText, 'showActiveItems': this.showActiveItems };
    this.filterColumn.emit(searchItem);
  }

  toggleActive = () => {
    this.toggleActiveList.emit(this.showActiveItems);
    this.showActiveItems = !this.showActiveItems;
  }

  showDetails = (item: any, event: Event) => {
    event.stopPropagation();
    this.meatballMenu = '';
    this.showItemDetails.emit(item.itemId);
  }

  toggleEditModal = (item: any, event: Event) => {
    event.stopPropagation();

    this.meatballMenu = '';
    this.showEditModal.emit(item.itemId);
  }

  toggleStaffEditModal = (item: any, event: Event) => {
    event.stopPropagation();
    this.showStaffEditModal.emit(item.itemId);
  }

  toggleRemoveItem = (item: any, event: Event) => {
    event.stopPropagation();
    this.meatballMenu = '';
    this.removeItem.emit(item);
  }

  toggleApproveItem = (item: any, event: Event) => {
    event.stopPropagation();
    this.meatballMenu = '';
    this.approveItem.emit(item);
  }

  toggleDenyItem = (item: any, event: Event) => {
    event.stopPropagation();
    this.meatballMenu = '';
    this.denyItem.emit(item);
  }

  setMeatball = (meatballMenu: any, event: Event) => {
    event.stopPropagation();
    if (this.meatballMenu === meatballMenu) {
      this.meatballMenu = '';
      return;
    }
    this.meatballMenu = meatballMenu;
  }

  checkIfShowEdit(item: any): boolean {
    const showEdit = item.showEdit;

    if (showEdit === false) {
      return false;
    }

    return true;
  }

  handleRowClick = (item: any) => {
    this.rowClicked.emit(item);
    this.showItemDetails.emit(item.itemId);
  }

}
