<div class="container">
  <div class="content">
    <div class="title-row">
      <span class="ktitle-tiny">Attachments</span>
      <span class="klabel-medium">*Required field</span>
    </div>
    <div class="dropbox-container">
      <span class="ksubtitle-medium">Max file size 10mb; acceptable file formats include .pdf, .jpg, .png</span>
      <div class="dropbox">
        <mat-icon class="icon" fontSet="material-icons-round">upload</mat-icon>
        <span class="klabel-huge">Drag your files here</span>
        <span class="kbody-medium">or click here to upload from your computer</span>
      </div>
    </div>
  </div>
</div>