import {
  Component, OnDestroy, OnInit,
} from '@angular/core';
import { UpdateMyCourseRequestComponent } from './components/update-my-course-request/update-my-course-request.component';
import { MatDialog } from '@angular/material/dialog';
import { CourseRequestsService } from 'src/app/services/course-requests.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { MyCourseRequestDetailsComponent } from './components/my-course-request-details/my-course-request-details.component';
import { CoursesService } from 'src/app/services/courses.service';
import { VerificationComponent } from 'src/app/components/verification/verification.component';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-my-course-requests',
  templateUrl: './my-course-requests.component.html',
  styleUrls: [ './my-course-requests.component.scss' ],
})
export class MyCourseRequestsComponent implements OnInit, OnDestroy {
  courseDataSubscription = new Subscription();
  _coursesData: any;
  coursesData$: Observable<any>;
  tableData: any;
  loading = false;

  offset = 0;
  selectedItemsPerPage = 25;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private courseRequestService: CourseRequestsService,
    private coursesService: CoursesService,
    private toast: ToastService,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this?.route?.snapshot?.params['id'] ? this.courseRequestService.getUserCourseRequests( this?.route?.snapshot?.params['id'], { sortColumn: 'courseName', sortDirection: 'ASC' }) : this.courseRequestService.getUserCourseRequests( null, { sortColumn: 'courseName', sortDirection: 'ASC' })
    this.courseDataSubscription = this.courseRequestService.courseRequestsData.subscribe((data: any) => {
      this._coursesData = data;
      // here we filter for only active courses
      const activeCourses = data?.rows?.filter((course: any) => course?.courseActiveFlag === 1);
      // const activeCourses = data?.rows
      const formatedTableData = activeCourses?.map((course: any) => {
        return {
          itemId: course.id,
          itemData: [
            {
              content: course?.courseName, secondLine: course.id, class: 'kbody-medium left-align wide bold',
            },
            {
              content: course?.preRecordedOption === 'ASYNC' ? 'Async' :`${new Date(course?.dateOffered).toLocaleDateString()}`, class: 'medium left-align bold', secondLine: course?.preRecordedOption === 'ANYTIME' ? 'Anytime' : course?.preRecordedOption === 'ASYNC' ? '' : `${new Date(course.sessionStartDate).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })}-${new Date(course.sessionEndDate).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })}`,
            },
            { content: new Date(course?.courseCreatedDate).toLocaleDateString(), class: 'medium-skinny left-align' },
            { content: course?.currentStatusKid.charAt(0).toUpperCase() + course?.currentStatusKid.slice(1).toLowerCase(), class: `chip ${course?.currentStatusKid}` },
          ],
        }

      })
      this.tableData = {
        tableTitle: 'Course Requests',
        error: data?.error || null,
        columnTitles: [
          {
            title: 'Course', class: 'wide left-align', sortBy: 'courseName',
          },
          {
            title: 'Date & Time', class: 'medium left-align', icon: 'date_range', sortBy: 'dateOffered',
          },
          {
            title: 'Submitted on', class: 'medium-skinny left-align', sortBy: 'courseCreatedDate',
          },
          {
            title: 'Status', class: 'chip-title', sortBy: 'currentStatusKid',
          },
          // {
          //   title: '', class: 'final-options', noCircle: true, notClickable: true,
          // },
        ],
        totalItems: data?.pagination.totalItems,
        curPage: data?.pagination.curPage,
        totalPages: data?.pagination.totalPages,
        data: formatedTableData,
      }
      this.loading = data ? false : true;
    })
  }

  onPageChanged(page: number) {
    const offset = this.calculateOffset(page);
    this.offset = offset;
    this.searchForMyCourseRequests();
  }

  onItemsPerPageChange(itemsPerPage: number) {
    this.selectedItemsPerPage = itemsPerPage;
    this.offset = 0;
    this.searchForMyCourseRequests();
  }

  // paginationEvent = (pageDirection: string) => {
  //   this.loading = true;
  //   this.tableData.data = [];
  //   this.buildingsService.getPagination(pageDirection);
  // }

  calculateOffset(page: number): number {
    return (page - 1) * this.selectedItemsPerPage;
  }

  searchForMyCourseRequests = () => {
    this.loading = true;
    this.tableData.data = []
    this?.route?.snapshot?.params['id'] ?
      this.courseRequestService.getUserCourseRequests(this?.route?.snapshot?.params['id'], { sortColumn: 'courseName', sortDirection: 'ASC' }) :
      this.courseRequestService.getUserCourseRequests(null, { sortColumn: 'courseName', sortDirection: 'ASC' })
  }

  toggleUpdateModal = () => {
    this.dialog.open(UpdateMyCourseRequestComponent);
  }

  showItemDetails = (item: any) => {
    const selectedCourse = this._coursesData?.rows?.find((course: any) => course.id === item);
    const dialogRef = this.dialog.open(MyCourseRequestDetailsComponent, { data: selectedCourse })
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'cancel-request') {
        const itemToRemove = { itemId: selectedCourse.id };
        this.removeItem(itemToRemove);
      }
    })
  }

  removeItem = (item: any) => {
    const selectedCourse = this._coursesData?.rows?.find((course: any) => course.id === item.itemId);
    const verficationRef = this.dialog.open(VerificationComponent, {
      data: {
        type: 'alert',
        title: 'Are you sure?',
        confirmButtonTitle: 'Yes, Delete Request',
        cancelButtonTitle: 'Cancel',
        text: 'Are you sure you want to delete this course request?',
      },
    })
    verficationRef.afterClosed().subscribe((result) => {
      if (result === 'verified') {
        this.coursesService.deactivateCourse(selectedCourse.courseId).subscribe({
          next: (response: any) => {
            this.toast.setToast({
              text: 'Course request canceled successfully',
              type: 'success',
              dismissible: true,
              icon: true,
            })
            this?.route?.snapshot?.params['id'] ? this.courseRequestService.getUserCourseRequests( this?.route?.snapshot?.params['id']) : this.courseRequestService.getUserCourseRequests()
          },
          error: (error) => {
            this.toast.setToast({
              text: 'There was an error deactivating the course',
              type: 'error',
              dismissible: true,
              icon: true,
            })
            throw error;
          },
        })
        this?.route?.snapshot?.params['id'] ? this.courseRequestService.getUserCourseRequests( this?.route?.snapshot?.params['id']) : this.courseRequestService.getUserCourseRequests()
      }
    })
  }

  sortColumn = (sortOrder: any) => {
    let columnName = '';
    this.tableData.data = [];
    this.tableData.isLoading = true;
    this.loading = true;

    sortOrder.sortBy ? columnName = sortOrder.sortBy : null;
    const additionalParameters = { sortColumn: columnName, sortDirection: sortOrder.direction };
    this?.route?.snapshot?.params['id'] ? this.courseRequestService.getUserCourseRequests( this?.route?.snapshot?.params['id'], additionalParameters ) : this.courseRequestService.getUserCourseRequests( null, additionalParameters )
  }

  ngOnDestroy(): void {
    this.courseDataSubscription.unsubscribe();
  }

}
