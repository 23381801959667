import {
  Component, Inject, OnDestroy, OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA, MatDialog, MatDialogRef,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { VerificationComponent } from 'src/app/components/verification/verification.component';
import { CourseRequestsService } from 'src/app/services/course-requests.service';
import { EntityService } from 'src/app/services/entity.service';
import { LoadingSpinnerService } from 'src/app/services/loading-spinner.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-update-my-course-request',
  templateUrl: './update-my-course-request.component.html',
  styleUrls: [ './update-my-course-request.component.scss' ],
})
export class UpdateMyCourseRequestComponent implements OnInit, OnDestroy {
  course: any = {
    name: '',
    creditHours: '',
    courseType: '',
    instructor: '',
    cost: '',
    webUrl: '',
    description: '',
    async: false,
    virtual: false,
    anytime: false,
    location: '',
    roomNumber: '',
    courseDate: '',
    startTime: '',
    endTime: '',
  }
  steps = [ { completed: false, text: 'General Info' }, { completed: false, text: 'Review & finish' } ]
  selectedStep = this.steps[0]
  entity: any;
  entitySubscription: any;
  courseTypes: any;
  courseRequestSub: any;
  originalFormattedCourse: any;

  constructor(
    public dialogRef: MatDialogRef<UpdateMyCourseRequestComponent>,
    public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
    private entityService: EntityService,
    private courseRequestsService: CourseRequestsService,
    private toastService: ToastService,
    private loadingSpinnerService: LoadingSpinnerService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.entityService.getEntity('CourseTypes')
    this.entitySubscription = this.entityService.entityData$.subscribe((entityData: any) => {
      this.entity = entityData
      // get the active course types for the general info step. the only way to call for all the course types is through the entity.
      const activeCourseTypes = entityData?.CourseTypes?.filter((courseType: any) => courseType.activeFlag)
      this.courseTypes = activeCourseTypes
    })
  }

  closeDialog = () => {
    this.dialogRef.close();
  }

  stepForward = () => {
    this.selectedStep.completed = true;
    this.selectedStep = this.steps[this.steps.indexOf(this.selectedStep) + 1];
  }

  stepBack = () => {
    this.selectedStep = this.steps[this.steps.indexOf(this.selectedStep) - 1];
  }

  nextDisabledCheck = () => {
    if (this.selectedStep === this.steps[0]) {
      return !this.course.name || !this.course.creditHours || !this.course.courseType || !this.course.description || !this.course.location
    }
    return false
  }

  submitCourseRequest = () => {
    // if the course is async, we remove the dateOffered key value pair from the course object
    if (this.course.async) {
      delete this.course.courseDate
    }
    // here we remove any null key value pairs from the course object
    Object.keys(this.course).forEach((key) => (this.course[key] === null || this.course[key] === '') && delete this.course[key])
    this.loadingSpinnerService.setIsLoading(true)
    this.courseRequestSub = this.courseRequestsService.submitCourseRequest(this.course).subscribe({
      next: (response: any) => {
        this.dialogRef.close();
        this?.route?.snapshot?.params['id'] ? this.courseRequestsService.getUserCourseRequests( this?.route?.snapshot?.params['id'] ) : this.courseRequestsService.getUserCourseRequests()

        this.loadingSpinnerService.setIsLoading(false)
        this.toastService.setToast({ text: 'Course Request Submitted', type: 'success' })
      },
      error: (error) => {
        this.loadingSpinnerService.setIsLoading(false)
        console.log('error', error);
        this.dialog.open(VerificationComponent, {
          data: {
            type: 'alert', title: 'Oops, something went wrong', text: error.error.statusCode === 4003 ? 'There already exists a course with that name, please rename your course.' : error?.error?.pgErrorInfo?.message || error?.error?.message,
          },
        })
      },
    })
  }

  ngOnDestroy(): void {
    this.entitySubscription?.unsubscribe();
    this.courseRequestSub?.unsubscribe();
  }

}
