import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: [ './avatar.component.scss' ],
})
export class AvatarComponent {
  user$: Observable<any>;
  entity$: Observable<any>;
  firstName: string;
  lastName: string;

  @Input() size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'md';
  @Input() inputInitials: string[];
  initials: string[] = [ 'A', 'B' ];

  imageSrc = ''
  // imageSrc = 'https://images.unsplash.com/photo-1617078262771-c2c39ac6867b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1364&q=80'

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.user$ = this.authService.user$
    this.entity$ = this.authService.entity$

    this.user$.subscribe((user: any) => {
      if (!user) return;
      this.firstName = user.firstName;
      this.lastName = user.lastName;
    })
  }

  getInitials(): string[] {
    if ( this.inputInitials ) {
      return this.inputInitials;
    }
    return [ this.firstName.charAt(0).toLocaleUpperCase(), this.lastName.charAt(0).toLocaleUpperCase() ];

  }
}
