import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { ToastService } from './toast.service';

@Injectable({ providedIn: 'root' })
export class EntityService {
  private _entityData$ = new BehaviorSubject<any>(null);
  public readonly entityData$ = this._entityData$.asObservable();

  user: any;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private toast: ToastService,
  ) {
    this.authService.user$.subscribe((user: any) => this.user = user)
  }


  getEntity = (includedAssociations: any) => {
    return this.http.get(`${environment.expressUrl}/entities`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: { 'loginName': this.user.loginName, includedAssociations: includedAssociations },
      })
      .subscribe({
        next: (response: any) => {
          this._entityData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  updateEntity = (entityData: any) => {

    const body = {
      loginName: this.user.loginName,
      ...entityData,
    }


    return this.http.put(
      `${environment.expressUrl}/entities`,
      body,
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
      .subscribe({
        next: (response: any) => {
          this._entityData$.next(response.data.current);

          this.toast.setToast({
            text: 'Entity updated',
            type: 'success',
            icon: true,
            dismissible: true,
          })
        },
        error: (error) => {
          console.log('error', error);
          this.toast.setToast({
            text: 'Error updating entity',
            type: 'error',
            icon: true,
            dismissible: true,
          })
        },
      })
  }

}
