<div class="modal">
  <div>
    <div class="title-row">
      <div>
        <mat-icon fontSet="material-icons-round">playlist_add</mat-icon>
        <span class="kbody-large">{{data ? 'Edit' : 'Submit'}} Course Request</span>
      </div>
      <mat-icon class="close-icon" (click)="closeDialog()" fontSet="material-icons-round">close</mat-icon>
    </div>
    <div>
      <app-stepper [steps]="steps" [selectedStep]="selectedStep" (selectedStepChange)="this.selectedStep = $event"></app-stepper>
      <app-course-request-general-info [courseTypes]="courseTypes" [course]="course" *ngIf="selectedStep.text === 'General Info'"></app-course-request-general-info>
      <app-course-request-attachments *ngIf="selectedStep.text === 'Attachments'"></app-course-request-attachments>
      <app-course-request-review [course]="course" *ngIf="selectedStep.text === 'Review & finish'"></app-course-request-review>
    </div>
  </div>
  <div class="buttons" [ngClass]="{'right-align': selectedStep === this.steps[0]}">
    <app-button *ngIf="selectedStep !== this.steps[0]" (click)="stepBack()" [text]="'Previous Step'" [icon]="{materialIcon: 'arrow_back'}" [class]="'white'"></app-button>
    <div class="actions">
      <app-button [text]="'Cancel'" [class]="'white'" (click)="closeDialog()"></app-button>
      <app-button *ngIf="selectedStep !== this.steps[this.steps.length - 1]" [disabled]="nextDisabledCheck()" [icon]="{ materialIcon: 'arrow_forward' }" [class]="'icon-right'" [text]="'Next Step'" (click)="stepForward()"></app-button>
      <app-button *ngIf="selectedStep === this.steps[this.steps.length - 1]" [icon]="{ materialIcon: 'done' }" [class]="'icon-right'" [text]="data ? 'Update' : 'Finish'" (click)="submitCourseRequest()"></app-button>
    </div>
  </div>
</div>