<div class="my-course-requests-container">
  <app-table
    (addNewItem)="toggleUpdateModal()"
    (sortColumn)="sortColumn($event)"
    [tableData]="tableData"
    [hideSearchBar]="true"
    [hideViewInactiveButton]="true"
    [hideShowDetailsButton]="false"
    [hideEditButton]="true"
    [showRemoveItemButton]="true"
    (removeItem)="removeItem($event)"
    (showItemDetails)="showItemDetails($event)"
    [isLoading]="loading"
    >
  </app-table>
  <app-loading-spinner class="spinner" *ngIf="loading"></app-loading-spinner>
</div>
