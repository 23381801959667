<div class="modal-content">
  <div class="title-row">
    <span class="kheader-strong" >General Information</span>
  </div>
  <div class="content">
    <div class="row status-row" *ngIf="showStatusRow">
      <div class="row">
        <div class="detail-col">
          <span class="kbody-small label">Submitted on</span>
          <span class="kbody-large text">{{ course.createdDate ? formatDate(course.createdDate) : '-' }}</span>
        </div>
        <div class="detail-col">
          <span class="kbody-small label">Submitted by</span>
          <span class="kbody-large text">{{ course.createdAppUser ? course.createdAppUser : '-' }}</span>
        </div>
      </div>
      <div class="detail-col">
        <span class="kbody-small label">Status</span>
        <div class="row">
          <span class="kbody-small status-chip" [ngClass]="course.currentCatalogStatusKid">{{ course.currentCatalogStatusKid === "SPECIAL_REQUEST_PENDING" ? 'Pending' :  course.currentCatalogStatusKid === "SPECIAL_REQUEST_APPROVED" ? 'Approved' : course.currentCatalogStatusKid === "SPECIAL_REQUEST_DENIED" ? 'Denied' : course.currentCatalogStatusKid}}</span>
          <span class="klink-small" (click)="viewReason()" *ngIf="course.currentCatalogStatusKid === 'SPECIAL_REQUEST_DENIED'">View Reason</span>
        </div>
      </div>
    </div>

    <div class="subtitle-container">
      <span class="kcaption-large subtitle">COURSE DETAILS</span>
      <div class="line"></div>
    </div>
    <div class="row">
      <div class="detail-col">
        <span class="kbody-small label">Course Name</span>
        <span class="kbody-large text">{{ course.name ? course.name : '-' }}</span>
      </div>
      <div class="detail-col">
        <span class="kbody-small label">Hours</span>
        <span class="kbody-large text">{{ course.creditHours ? course.creditHours : '-' }}</span>
      </div>
      <div class="detail-col">
        <span class="kbody-small label">Course Type</span>
        <span class="kbody-large text">{{ course.courseType ? course.courseType : '-' }}</span>
      </div>
    </div>
    <div class="row">
      <div class="detail-col">
        <span class="kbody-small label">Instructor</span>
        <span class="kbody-large text">{{ course.instructor ? course.instructor : '-' }}</span>
      </div>
      <div class="detail-col">
        <span class="kbody-small label">Course Cost</span>
        <span class="kbody-large text">{{ course.cost ? '$' + course.cost : '-' }}</span>
      </div>
      <div class="detail-col">
        <span class="kbody-small label">Web URL</span>
        <span class="kbody-large text">{{ course.webUrl ? course.webUrl : '-' }}</span>
      </div>
    </div>
    <div class="row">
      <div class="detail-col">
        <span class="kbody-small label">Course Description</span>
        <span class="kbody-large text">{{ course.description ? course.description : '-' }}</span>
      </div>
    </div>
    <div class="subtitle-container">
      <span class="kcaption-large subtitle">COURSE OFFERING, LOCATION, DATE & TIME</span>
      <div class="line"></div>
    </div>
    <div class="row">
      <div class="switch-detail">
        <label class="switch">
          <input type="checkbox" [(ngModel)]="course.async" disabled="true">
          <span class="slider round"></span>
        </label>
        <span class="kbody-medium">Async</span>
      </div>
      <div class="switch-detail">
        <label class="switch">
          <input type="checkbox" [(ngModel)]="course.virtual" disabled="true">
          <span class="slider round"></span>
        </label>
        <span class="kbody-medium">Virtual</span>
      </div>
      <div class="switch-detail" *ngIf="!course.async">
        <label class="switch">
          <input type="checkbox" [(ngModel)]="course.anytime" disabled="true">
          <span class="slider round"></span>
        </label>
        <span class="kbody-medium">Anytime</span>
      </div>
    </div>
    <div class="row">
      <div class="detail-col">
        <span class="kbody-small label">Location</span>
        <span class="kbody-large text">{{ course.location ? course.location : '-' }}</span>
      </div>
      <div class="detail-col">
        <span class="kbody-small label">Room Number</span>
        <span class="kbody-large text">{{ course.roomNumber ? course.roomNumber : '-' }}</span>
      </div>
    </div>
    <div class="row">
      <div class="detail-col">
        <span class="kbody-small label">Date</span>
        <span class="kbody-large text">{{ course.courseDate ? course.courseDate : '-' }}</span>
      </div>
      <div class="detail-col" *ngIf="!course.anytime">
        <span class="kbody-small label">Course Time</span>
        <span class="kbody-large text">{{ course.startTime }} - {{ course.endTime }}</span>
      </div>
    </div>
  </div>
</div>
