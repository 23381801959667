import {
  Component, OnDestroy, OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CourseRequestResponseComponent } from './components/course-request-response/course-request-response.component';
import { CourseRequestsService } from 'src/app/services/course-requests.service';
import { CourseRequestDetailsComponent } from './components/course-request-details/course-request-details.component';
import { AdvancedFiltersComponent } from './components/advanced-filters/advanced-filters.component';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-course-requests',
  templateUrl: './course-requests.component.html',
  styleUrls: [ './course-requests.component.scss' ],
})
export class CourseRequestsComponent implements OnInit, OnDestroy {
  defaultSections = [
    {
      id: 0, text: 'Pending Requests', icon: 'pending_actions', statusFilterArr: 'SPECIAL_REQUEST_PENDING',
    },
    {
      id: 1, text: 'Approved Requests', icon: 'approval', statusFilterArr: 'SPECIAL_REQUEST_APPROVED',
    },
    {
      id: 2, text: 'Denied Requests', icon: 'do_not_disturb', statusFilterArr: 'SPECIAL_REQUEST_DENIED',
    },
    // {
    //   id: 3, text: 'Registration Requests', icon: 'playlist_add', statusFilterArr: '', // TODO: not sure what call to make here yet
    // },
  ]
  selectedSection = this.defaultSections[0]
  isLoading = false;
  tableData: any;
  courseDataSubscription: any;
  _coursesData: any;
  offset = 0;
  selectedItemsPerPage = 25;
  onlyShowManagedUsers = null;
  sortColumnName = 'createdDate';
  sortDirection = 'DESC';
  originalCourseRequestsResponse: any;
  filterString = '';

  manageAllUsers = false;

  permissionsService = this.authService.permissions$.subscribe((permissions) => {
    if (!permissions) return;
    const { MANAGE_ALL_AUTHORITY } = permissions;

    if (MANAGE_ALL_AUTHORITY == 1) {
      this.manageAllUsers = true;
    }
  });

  constructor(
    public dialog: MatDialog,
    private courseRequestService: CourseRequestsService,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.getRequests()
    this.courseRequestService.clearCourseRequestsData();
    this.isLoading = true;
    // subscribe to course requests data and format for the table
    this.courseDataSubscription = this.courseRequestService.courseRequestsData.subscribe((data: any) => {
      this._coursesData = data;
      this.originalCourseRequestsResponse = { ...data }
      // here we filter for only active courses
      const formatedTableData = data?.rows?.map((course: any) => {
        return {
          itemId: course.id,
          itemData: [
            {
              content: course.Course?.courseName, class: 'kbody-medium left-align bold', secondLine: course.id,
            },
            { content: course.Course?.trackingValue, class: 'skinny left-align' },
            {
              content: course.CourseItem?.preRecordedOption === 'ASYNC' ? 'Async' : `${new Date(course?.CourseItem.dateOffered).toLocaleDateString()}`,
              secondLine: course?.CourseItem?.preRecordedOption === 'ANYTIME' ? 'Anytime' : course.CourseItem?.preRecordedOption === 'ASYNC' ? '' : `${new Date(course.CourseItem.sessionStartDate).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })}-${new Date(course.CourseItem.sessionEndDate).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })}`,
              class: 'medium-medium left-align bold',
            },
            {
              // here we give the avatar the initials of the user who submitted the course request
              avatar: { initials: [ course.User?.firstName.charAt(0), course.User?.lastName.charAt(0) ] },
              content: course.User.fullName,
              secondLine: course?.User.contactEmail,
              class: 'wide left-align bold',
            },
            { content: new Date(course?.createdDate).toLocaleDateString(), class: 'medium-skinny left-align' },
          ],
        }

      })
      this.tableData = {
        tableTitle: this.selectedSection.text,
        error: data?.error || null,
        columnTitles: [
          { title: 'Course', sortBy: 'Course.course_name' },
          {
            title: 'Hours', class: 'skinny left-align', sortBy: 'Course.tracking_value',
          },
          {
            title: 'Date & Time', class: 'medium-medium left-align', icon: 'date_range', sortBy: 'CourseItem.date_offered',
          },
          {
            title: 'Submitted by', class: 'wide left-align', icon: 'person', sortBy: 'User.last_name',
          },
          {
            title: 'Submitted on', class: 'medium-skinny left-align', sortBy: 'createdDate',
          },
          // {
          //   title: '', class: 'final-options', noCircle: true, notClickable: true,
          // },
        ],
        activeTotal: data?.counts.activeCount,
        inactiveTotal: data?.counts.inactiveCount,
        totalItems: data?.pagination.totalItems,
        curPage: data?.pagination.curPage,
        totalPages: data?.pagination.totalPages,
        data: formatedTableData,

      }
      this.isLoading = data ? false : true;
    })
  }

  getRequests = () => {
    this.courseRequestService.clearCourseRequestsData();
    this.isLoading = true;
    let queryParams: any = {
      activeFlag: 1,
      filterString: this.filterString,
      sortColumn: this.sortColumnName,
      sortDirection: this.sortDirection,
      includedAssociations: 'Course, CourseItem, User',
      statusFilterArr: this.selectedSection.statusFilterArr,
      limit: this.selectedItemsPerPage,
      offset: this.offset,
    }
    if (this.onlyShowManagedUsers || !this.manageAllUsers) {
      queryParams= { ...queryParams, includeOnlyManagedUsers: true }
    }
    this.courseRequestService.getManagedCourseRequests(queryParams)
  }

  calculateOffset(page: number): number {
    return (page - 1) * this.selectedItemsPerPage;
  }

  onPageChanged(page: number) {
    const offset = this.calculateOffset(page);
    this.offset = offset;
    this.getRequests();
  }

  onItemsPerPageChange(itemsPerPage: number) {
    this.selectedItemsPerPage = itemsPerPage;
    this.offset = 0;
    this.getRequests();
  }

  toggleSelectedSection = (section: any) => {
    this.tableData.data = [];
    this.selectedSection = section
    this.offset = 0;
    if (section.id === 3) { return } // TODO: remove once 'Registration Requests' is figured out
    this.getRequests()
  }

  searchCourseRequests(filterString: any) {
    const { searchText } = filterString;
    this.filterString = searchText;
    this.offset = 0;
    this.getRequests();
  }

  sortColumn = (sortOrder: any) => {
    this.sortColumnName = sortOrder.sortBy;
    this.sortDirection = sortOrder.direction;

    const currentPage = this.originalCourseRequestsResponse?.pagination?.curPage || 1;
    const offset = this.calculateOffset(currentPage);
    this.offset = offset;

    this.getRequests();
  }

  showItemDetails = (item: any) => {
    const selectedCourse = this._coursesData?.rows?.find((course: any) => course.id === item);
    const dialogRef = this.dialog.open(CourseRequestDetailsComponent, { data: selectedCourse })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.openCourseRequestResponse({ ...selectedCourse, itemId: selectedCourse.id }, result);
      }
    });
  }

  toggleAdvancedFilters = () => {
    const advancedFiltersDialogRef = this.dialog.open(AdvancedFiltersComponent, { data: { showManagedUsers: this.onlyShowManagedUsers } });
    advancedFiltersDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.onlyShowManagedUsers = result.showManagedUsers;
        this.getRequests()
      }
    })
  }

  openCourseRequestResponse(course: any, type: 'approve' | 'deny') {
    const selectedCourse = this._coursesData?.rows?.find((courseItem: any) => courseItem.id === course.itemId);
    const dialogRef = this.dialog.open(CourseRequestResponseComponent, { data: { course: selectedCourse, approvalType: type } });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) { this.getRequests() }
    })
  }

  ngOnDestroy() {
    this.courseDataSubscription.unsubscribe();
  }

}
