import {
  Component, OnInit, OnDestroy,
} from '@angular/core';
import { UpdateTagComponent } from './components/update-tag/update-tag.component';
import { MatDialog } from '@angular/material/dialog';
import { TagDetailsComponent } from './components/tag-details/tag-details.component';
import { TagsService } from 'src/app/services/tags.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: [ './tags.component.scss' ],
})
export class TagsComponent implements OnInit, OnDestroy {
  originalTagResponse: any;
  tableData: any;
  tagSubscription: any;
  loading = false;
  selectedItemsPerPage = 25;
  isActive = false;

  searchText = '';
  sortColumnName: 'tag' | 'isVisibleFlag' | 'track' = 'tag';
  sortDirection: 'ASC' | 'DESC' = 'ASC';
  offset = 0;

  constructor(public dialog: MatDialog, private tagsService: TagsService) { }

  ngOnInit(): void {
    this.loading = true;

    this.tableData = {
      tableTitle: 'Tags',
      columnTitles: [
        { title: 'Tag Name', sortBy: 'tag' },
        {
          title: 'Color', notClickable: true, class: 'skinny',
        },
        // { title: 'Tracking', sortBy: 'track' },
        { title: 'Visible to Teachers', sortBy: 'isVisibleFlag' },
      ],
      data: [],
    }

    this.searchForTags();
    this.tagSubscription = this.tagsService.tagsData$.subscribe((data: any) => {
      if (!data) return;
      this.loading = false;

      this.originalTagResponse = { ...data };

      const formattedTableData = data?.rows?.map((tag: any) => {
        return {
          itemId: tag.id,
          itemData: [
            { content: tag?.tag, class: 'kstrong-medium' },
            {
              type: 'color', color: tag?.color, class: 'skinny dot',
            },
            // { content: tag?.track },
            { type: 'checkbox', checked: tag?.isVisibleFlag },
          ],
        }
      })
      this.tableData = {
        tableTitle: 'Tags',
        columnTitles: [
          { title: 'Tag Name', sortBy: 'tag' },
          {
            title: 'Color', notClickable: true, class: 'skinny',
          },
          // { title: 'Tracking', sortBy: 'track' },
          { title: 'Visible to Teachers', sortBy: 'isVisibleFlag' },
        ],
        // allSchoolYears: [
        //   '2023-2024', '2022-2023', '2021-2022', '2020-2021', '2019-2020', '2018-2019',
        // ],
        activeTotal: data?.counts.active,
        inactiveTotal: data?.counts.inactive,
        totalItems: data?.pagination.totalItems,
        curPage: data?.pagination.curPage,
        totalPages: data?.pagination.totalPages,
        data: formattedTableData,
      };
      this.loading = data ? false : true;
    })
  }

  searchForTags() {
    this.loading = true;
    this.tableData.data = [];
    const params = {
      tagNameFilter: this.searchText?.toLowerCase() || '',

      activeFlag: this.isActive ? 0 : 1 as 0 | 1,
      sortColumn: this.sortColumnName,
      sortDirection: this.sortDirection,
      limit: this.selectedItemsPerPage,
      offset: this.offset,
    }

    this.tagsService.getTags(params);
  }

  calculateOffset(page: number): number {
    return (page - 1) * this.selectedItemsPerPage;
  }

  onPageChanged(page: number) {
    const offset = this.calculateOffset(page);
    this.offset = offset;
    this.searchForTags();
  }

  onItemsPerPageChange(itemsPerPage: number) {
    this.selectedItemsPerPage = itemsPerPage;
    this.offset = 0;
    this.searchForTags();
  }

  getTagsFilteredByName = (searchItem: any) => {
    this.searchText = searchItem?.searchText;
    this.offset = 0;
    this.searchForTags();
  }

  sortColumn = (sortOrder: any) => {
    this.sortColumnName = sortOrder.sortBy;
    this.sortDirection = sortOrder.direction;

    const currentPage = this.originalTagResponse?.pagination?.curPage || 1;
    const offset = this.calculateOffset(currentPage);
    this.offset = offset;

    this.searchForTags();
  }

  toggleActiveList = (showActiveItems: boolean) => {
    this.isActive = showActiveItems;
    this.offset = 0;
    this.searchForTags();
  }

  showEditModal = (item: any) => {
    const tagData = this.originalTagResponse?.rows?.find((tag: any) => {
      return tag?.id === item;
    });
    const dialogRef = this.dialog.open(UpdateTagComponent, { data: tagData });

    dialogRef.afterClosed().subscribe((response: any) => {
      if (response?.statusCode === 1000) {
        this.searchForTags();
      }
    });
  }

  showItemDetails = (itemId: any) => {
    const tagData = this.originalTagResponse?.rows?.find((tag: any) => {
      return tag?.id === itemId;
    });
    const dialogRef = this.dialog.open(TagDetailsComponent, { data: tagData });

    dialogRef.afterClosed().subscribe((response: any) => {
      if (response?.statusCode === 1000 || response === 'reload') {
        this.searchForTags();
      }
    });
  }

  addNewTag = () => {
    const dialogRef = this.dialog.open(UpdateTagComponent);
    dialogRef.afterClosed().subscribe((response: any) => {
      if (response?.statusCode === 1000) {
        this.searchForTags();
      }
    });
  }

  ngOnDestroy(): void {
    this.tagSubscription.unsubscribe();
  }
}
