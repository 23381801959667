import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { environment } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })
export class CourseRequestsService {
  private _courseRequestsData$ = new BehaviorSubject<any>(null);
  public readonly courseRequestsData = this._courseRequestsData$.asObservable();

  _user$: any;

  constructor(private http: HttpClient,
    private authService: AuthService) {
    this.authService.user$.subscribe((user: any) => this._user$ = user)
  }

  // TODO: this will need some significant updates once express updates their endpoint. for example, we no longer handle time or dates like this, there is no cost, description, or credit hours field,
  submitCourseRequest = (courseRequest: any) => {
    // helper function to change the isostring to the format hh:mm
    const formatTime = (time: any) => {
      const newTime = new Date(time);
      newTime.toLocaleTimeString();
      let hours = '' + newTime.getHours();
      let minutes = '' + newTime.getMinutes();
      if (hours.length < 2)
        hours = '0' + hours;
      if (minutes.length < 2)
        minutes = '0' + minutes;
      return [ hours, minutes ].join(':');
    }
    // if there is a start time, we have to format it
    if(courseRequest?.startTime) {
      // here we convert our times to the correct format if they are still isostrings
      if(courseRequest?.startTime?.length > 5) {
        const formattedStartTime = formatTime(courseRequest?.startTime);
        courseRequest.startTime = formattedStartTime;
      }
      // here we setup the sessionStartDate property, which is used for the start time
      courseRequest.sessionStartDate = new Date(courseRequest.courseDate)
      courseRequest.sessionStartDate.setHours(courseRequest?.startTime?.split(':')[0])
      courseRequest.sessionStartDate.setMinutes(courseRequest.startTime?.split(':')[1])
      courseRequest.sessionStartDate = courseRequest?.sessionStartDate?.toISOString()
    }
    // if there is an endtime, we have to format it as well
    if(courseRequest?.endTime) {
      if(courseRequest?.endTime?.length > 5) {
        const formattedEndTime = formatTime(courseRequest?.endTime);
        courseRequest.endTime = formattedEndTime;
      }
      // Here we setup the sessionEndDate property, which is used for the end time
      courseRequest.sessionEndDate = new Date(courseRequest.courseDate)
      courseRequest.sessionEndDate.setHours(courseRequest.endTime?.split(':')[0])
      courseRequest.sessionEndDate.setMinutes(courseRequest.endTime?.split(':')[1])
      courseRequest.sessionEndDate = courseRequest?.sessionEndDate?.toISOString() || null
    }

    const formattedCourseRequest = {
      'loginName': this._user$.loginName,
      'courseName': courseRequest.name,
      'courseTypeId': parseFloat(courseRequest.courseTypeObject.id), //number @REQUIRED
      'userId': parseFloat(this._user$.id), //number @Required
      'trackingValue': parseFloat(courseRequest.creditHours),
      'courseCost': parseFloat(courseRequest.cost),
      'courseDescription': courseRequest.description,
      'itemsArray': [
        {
          'preRecordedOption': courseRequest.async ? 'ASYNC' : courseRequest.anytime ? 'ANYTIME' : null,
          'roomNumber': courseRequest.roomNumber,
          'sessionStartDate': courseRequest.sessionStartDate, // this is the start time of the class
          'sessionEndDate': courseRequest.sessionEndDate, // this is the end time of the class
          'regStartDate': '2024-09-29',
          'regEndDate': '2024-10-07',
          'dateOffered': courseRequest.courseDate,
          'startTimeHr': 11,
          'startTimeMin': 30,
          'startAmPm': 'AM',
          'endTimeHr': 2,
          'endTimeMin': 0,
          'endAmPm': 'PM',
          'location': courseRequest.location,
          'instructor': courseRequest.instructor,
          'instructorId': null,
          'courseSize': 11,
          'allowWaitListFlag': 1,
          'hyperlink': courseRequest.webUrl,
          'virtualFlag': courseRequest.virtual ? 1 : 0,
        },
      ],
    }

    return this.http.post(
      `${environment.expressUrl}/courses/course-requests`,
      formattedCourseRequest,
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

  // we use this call to show the course request details in the profile page:course-requests tab. currently we call it with AllCourseDetails, but we may be able to narrow that if needed.
  getCourseRequest = (courseRequestId: any) => {
    return this.http.get(`${environment.expressUrl}/courses/course-requests/${courseRequestId}`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: { loginName: this._user$.loginName, includedAssociations: 'AllCourseDetails' },
      })
  }


  // we use this call to show the course request list in the profile page:course-requests tab
  getUserCourseRequests = ( otherUsersId?: any, additionalParameters?: any) => {
    return this.http.get(`${environment.expressUrl}/courses/registration/pd-plan`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          loginName: this._user$.loginName,
          limit: 10000,
          specialRequestFlag: 1,
          userId: otherUsersId ? otherUsersId : this._user$.id,
          ...additionalParameters,
        },
      })
      .subscribe({
        next: (response: any) => {
          this._courseRequestsData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  // we use this call to show the course request list in the Manage Courses:course-requests tab
  getManagedCourseRequests = ( additionalParameters?: any) => {
    return this.http.get(`${environment.expressUrl}/courses/course-requests`,
      {
        headers: { 'Content-Type': 'application/vnd.api+json' },
        params: {
          loginName: this._user$.loginName,
          ...additionalParameters,
        },
      })
      .subscribe({
        next: (response: any) => {
          this._courseRequestsData$.next(response.data);
        },
        error: (error) => {
          console.log('error', error);
        },
      })
  }

  // this clears the course requests data in the Manage Courses:course-requests tab
  clearCourseRequestsData = () => {
    this._courseRequestsData$.next(null);
  }

  // this is used to deny a course request
  denyCourseRequest = (courseRequest: any, denialReason: any) => {
    return this.http.patch(
      `${environment.expressUrl}/courses/course-requests/${courseRequest.courseId}/reject`,
      {
        loginName: this._user$.loginName,
        userId: parseFloat(courseRequest.userId),
        courseItemId: parseFloat(courseRequest.courseItemId),
        comment: denialReason,
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }

  // this is used to approve a course request
  approveCourseRequest = (
    courseChanges: any, userId: any, courseId: any, courseItemId: any,
  ) => {
    // if there is a courseChanges.courseCost, we need to convert it to a string
    if(courseChanges?.courseCost) {
      courseChanges.courseCost = courseChanges.courseCost.toString();
    }

    return this.http.patch(
      `${environment.expressUrl}/courses/course-requests/${courseId}/approve`,
      {
        loginName: this._user$.loginName,
        userId: parseFloat(userId),
        courseItemId: parseFloat(courseItemId),
        courseId: parseFloat(courseId),
        updateCourseParams: {
          loginName: this._user$.loginName, // i think this is a mistake in the api, but it is required
          ...courseChanges,
        },
      },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    )
  }
}
