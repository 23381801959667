<div class="modal">
  <div class="modal-header">
    <div>
      <mat-icon
      fontSet="material-icons-round"
      class="close-icon"
      >post_add</mat-icon>
      <span class="kbody-large">Course Request Details</span>
    </div>
    <mat-icon
      fontSet="material-icons-round"
      class="close-icon"
      (click)="closeModal()"
      >close</mat-icon>
  </div>
  <app-loading-spinner class="spinner" *ngIf="isLoading"></app-loading-spinner>
  <app-course-request-review [showStatusRow]="true" *ngIf="!isLoading" [course]="course"></app-course-request-review>
  <div *ngIf="!isLoading" class="buttons">
    <app-button (click)="cancelRequest()" [class]="'red'" [text]="'Cancel Request'"></app-button>
    <app-button (click)="closeModal()" [class]="'grey'" [text]="'Close this Window'"></app-button>
  </div>
</div>
