import {
  Component, EventEmitter, Input, OnInit, Output, ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import Course from '../../course.class';


@Component({
  selector: 'app-course-sorting',
  templateUrl: './course-sorting.component.html',
  styleUrls: [ './course-sorting.component.scss' ],

})
export class CourseSortingComponent implements OnInit {
  @ViewChild('requirementsinput') requirementsinput: any;
  @ViewChild('tagsinput') tagsinput: any;
  @ViewChild('categoriesinput') categoriesinput: any;
  @Output() stepForward = new EventEmitter<boolean>();
  @Output() stepBack = new EventEmitter<any>();
  @Output() closeDialogEmitter = new EventEmitter<any>();
  @Input() course: Course;
  @Input() requirements: Array<any>;
  @Input() tags: Array<any>;
  @Input() categories: Array<any>;
  @Input() isEditMode: boolean;

  RequirementList: Array<any>;


  constructor(public dialog: MatDialog) { }


  ngOnInit(): void {
    this.RequirementList = this.newRequirementList(this.course.requirementsList);
  }

  updateLocalCourseParameter = (event: any, parameter: 'requirements' | 'tags' | 'categories') => {
    let selectedValue: any;

    if (parameter === 'requirements') {
      for (let i = 0; i < this.course.requirementsList.length; i++) {
        this.removeAllFromArray(this.course.requirementsList[i], 'requirements');
      }
      const arr = Array.from(event[0]);
      for (let i = 0; i < arr.length; i++) {
        selectedValue = this.findRequirement(this.requirements, arr[i]);
        if (selectedValue.nodeType === 'SINGLE' || selectedValue.nodeType === 'GROUP_LEAF') {
          if (!this.isRequirementinList(this.course.requirementsList, arr[i])) {
            this.course.requirementsList = [ ...this.course.requirementsList, selectedValue ];
          }
        }
        selectedValue = '';
      }
      this.RequirementList = this.newRequirementList(this.course.requirementsList);
    }
    if (parameter === 'tags') {
      selectedValue = this.tags.find((item: any) => item.tag === event.target.value);
      this.tagsinput.nativeElement.value = '';
    }
    if (parameter === 'categories') {
      selectedValue = this.categories.find((item: any) => item.category === event.target.value);
      this.categoriesinput.nativeElement.value = '';
    }

    if (!selectedValue) { return }
    this.course.addToArray(parameter, selectedValue);
  }


  findRequirement(dataSet: any[], value: any) {
    let result: any;
    for (const requirement of dataSet) {
      if (requirement.Id === value) {
        result = requirement;
        break;
      }
      else {
        if (Array.isArray(requirement.children)) {
          for (const child of requirement.children) {
            if (child.Id === value) {
              result = child;
              break;
            }
            else {
              if (Array.isArray(child.children)) {
                for (const leaf of child.children) {
                  if (leaf.Id === value) {
                    result = leaf;
                    break;
                  }
                }
              }
            }
          }
        }
      }
    }
    return result;
  }

  isRequirementinList(dataSet: any[], value: any) {
    let result = false;
    for (const requirement of dataSet) {
      if (requirement.Id === value) {
        result = true;
        break;
      }
    }
    return result;
  }

  newRequirementList(dataSet: any[]) {
    const parents = dataSet.reduce((acc, element) => {
      const requirement = element.topMostParentId;
      (acc[requirement] = acc[requirement] || []).push(element);
      return acc;

    }, {});
    return Object.values(parents);
  }
  updateCourse = () => {
    this.stepForward.emit(true);
  }

  stepBackward = () => {
    this.stepBack.emit();
  }

  removeAssetFromArray = (asset: any, param: 'categories' | 'requirements' | 'tags') => {
    const id = asset.id || asset.requirementId;
    this.course.removeFromArray(param, id);
    this.RequirementList = this.newRequirementList(this.course.requirementsList);
  }

  removeAllFromArray = (asset: any, param: 'categories' | 'requirements' | 'tags') => {
    const requirementsToRemove = [];
    for (let i = 0; i < this.RequirementList.length; i++) {
      const req = this.RequirementList[i]
      if (req.length === 1 && req[0].topMostParentId === asset.topMostParentId) {
        requirementsToRemove.push(req[0]);
      }
      else {
        for (let x = 0; x < req.length; x++) {
          if (req[x].topMostParentId === asset.topMostParentId) {
            requirementsToRemove.push(req[x]);
          }
        }
      }
    }
    for (let i = 0; i < requirementsToRemove.length; i++) {
      const id = requirementsToRemove[i].requirementId;
      this.course.removeFromArray(param, id);
    }
    this.RequirementList = this.newRequirementList(this.course.requirementsList);
  }


  closeDialog = () => {
    this.closeDialogEmitter.emit();
  }

}
