import { Component } from '@angular/core';

@Component({
  selector: 'app-course-request-attachments',
  templateUrl: './course-request-attachments.component.html',
  styleUrls: [ './course-request-attachments.component.scss' ],
})
export class CourseRequestAttachmentsComponent {

}
